@import "../../../scss/foundation/functions";
@import "../../../scss/responsive-base/mixins";
@import "../../../scss/base_settings";

html.page-showproduct {
  .product-images {
    .ProductImagesBody {
      display: none;
    }
    &.slider-mobile {
      @media #{$medium-up} {
        #slider-wrapper {
          display: none;
        }
      }
      @media #{$small-only} {
        > div:first-child {
          display: none;
        }
      }
    }
    &.slider-all {
      > div:first-child {
        display: none;
      }
    }
    #slider-wrapper {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      @media #{$large-up} {
        justify-content: flex-end;
      }
    }
    #product-slider-thumbs {
      width: 100%;
      display: flex;
      order: 2;
      overflow: auto;
      margin-top: 0.5rem;
      @media #{$large-up} {
        @include scroll-bar();
        height: 100%;
        position: absolute;
        left: 0;
        margin-top: 0;
        flex-direction: column;
        width: 18%;
        order: 1;
        padding-top: 3px;
        padding-left: 3px;
      }
      li {
        transition: opacity 0.4s linear;
        opacity: 0.7;
        max-width: 150px;
        &:hover,
        &.selected {
          opacity: 1;
        }
        &:not(:last-child) {
          margin: 0 0.5rem 0 0;
        }
        @media #{$large-up} {
          box-shadow: rgb(85, 85, 85) 1px 1px 3px;
          &:not(:last-child) {
            margin: 0 0 0.5rem 0;
          }
        }
      }
    }
    #product-slider {
      order: 1;
      width: 100%;
      position: relative;
      @media #{$large-up} {
        order: 2;
        width: 80%;
      }
      .slick-slide {
        margin: 0 1px;
        position: relative;
      }
      .slick-slider {
        margin: 0 auto;
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
}
