//CHANNEL2
html.channel6,
html.channel7 {
  #rest-category-wrapper.top-mode #filter-wrapper .filters .filter .title {
    font-size: 0.8rem;
  }

  #product-list-container a.load-more {
    font-weight: bold;
  }

  #subcategories-container {
    display: flex !important;
    flex-wrap: wrap;
    .subcategories-item {
      width: auto;
      margin: 0 0 10px 0;
      float: none;
      &:not(:first-child) {
        margin-left: 15px;
      }
      .subcategories-name {
        font-weight: 300;
        font-size: 14px;
        padding: 0.5rem 0.7rem;
        border: 1px solid $c2-primary-color;
        border-radius: 100px;
      }
    }
  }

  .left-cat-wrapper {
    display: block;
    .selected a {
      text-decoration: underline;
    }
  }

  .category-page-wrapper,
  .releware-recommendation-wrapper,
  #startpage_list .list-category,
  .list-category {
    ul {
      li {
        padding: 1rem 0.5rem;
        border: 1px solid #eee;
        .product-image {
        }

        .product-wrapper {
          display: flex;
          flex-direction: column;
        }

        .product-buttons,
        .product-buttons:hover,
        .product-buy-control {
          display: flex !important;
          flex-direction: column;
          order: 1;
        }

        .product-buttons {
          margin-top: 5px;
          a {
            width: 100%;
            margin: 0;
            font-weight: 300;

            span {
              font-weight: 300;
            }
            &:not(:last-child) {
              display: none;
            }
          }
        }

        .product-stock-indication {
          display: none;
        }

        .product-list-description {
        }

        .product-name {
          a {
            font-weight: 300;
            font-size: 18px;
          }

          h3 {
          }

          span.product-subname {
          }
        }

        .product-list-article-number span {
        }

        .product-price {
          .price-amount {
            a {
            }
            a:hover {
            }
          }
        }
      }
    }
  }
}

//CHANNEL3
html.channel7 {
  .category-page-wrapper,
  .releware-recommendation-wrapper,
  #startpage_list .list-category,
  .list-category {
    ul {
      li {
        .product-buttons {
          a {
            color: $c3-primary-color;
            font-size: 0.9rem;
            padding: 10px 20px;
            border-radius: 5px;
            text-transform: none;
            span {
              font-weight: $font-weight-bold !important;
            }
            &:hover {
              background: $c3-secondary-dark-color;
            }
          }
        }
      }
    }
  }

  #subcategories-container {
    .subcategories-item {
      .subcategories-name {
        border-radius: 5px;
        font-weight: $font-weight-bold;
        color: $c3-primary-color;
        border: 2px solid $c3-primary-color;
      }
    }
  }
}
