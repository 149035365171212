@import "ebeling-start-page-settings";

/*-------------------------
-------ITEM CLASSES--------
--------------------------*/

//TITLE
.startpage-custom-item.title {
  text-align: center;
  border-top: 1px solid $border-color;
  margin: 15px 4% -31px;
  width: 92% !important;
  h2,
  h3,
  h4,
  h5 {
    padding: 0 65px;
    display: inline-block;
    position: relative;
    top: -21px;
    background: white;
  }
  @media #{$small-only} {
    margin: 30px 0px -31px;
    width: 100% !important;
    h2,
    h3,
    h4,
    h5 {
      padding: 0 10px;
    }
  }
}

//BORDER-BOX (TEXT UNDER)
.startpage-custom-item.border-box {
  padding: 10px 10px 15px;
  border: 1px solid $border-color;
  text-align: center;
  .custom-item-text {
    display: block;
    padding-top: 10px;
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

//TEXT-BOX (ONLY TEXT)
.text-box {
  padding: 2rem 1rem;
}

//TEXT BLOCK GREY BACKGORUND
.startpage-custom-item.text-block {
  text-align: center;
  padding: 10px 15px;
  background: #f9f9f9;
  @media #{$medium-only} {
    padding: 20px 30px;
  }
  @media #{$large-up} {
    padding: 30px 60px;
  }
}

//IMG ZOOM HOVER
.startpage-custom-item.zoom-hover {
  overflow: hidden;
  img {
    -webkit-transition: all 4s;
    -o-transition: all 4s;
    transition: all 4s;
  }
  &:hover {
    img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

//WIDE-IMAGE
.wide-image {
  @media #{$medium-down} {
    width: 100%;
    position: relative;
    height: 500px;
    overflow: hidden;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      max-width: 9999px;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  @media #{$small-only} {
    height: 400px;
  }
}

/*-------------------------
-------DIV CLASSES---------
--------------------------*/

.center {
  text-align: center;
}

.under-text {
  padding: 1rem;
  background: white;
  box-shadow: 1px 3px 8px #eee;
}

.startpage-custom-item .price {
  font-weight: $font-weight-bold;
  font-size: 1.2rem;
}

//TRENDING BRANDS
.trending-brands {
  .grid-row {
    div {
      padding: 10px;
      border: 1px solid $border-color;
      display: block;
    }
    img {
      display: inline-block !important;
    }
  }
}

//TRENDING BRANDS SMALL
.trending-brands-small {
  .grid-row {
    div {
      padding: 30px;
      line-height: 60px;
      border: 1px solid $border-color;
      display: block;
    }
    img {
      display: inline-block !important;
      max-width: 75%;
      max-height: 50px;
    }
  }
}

#brandlogos {
  text-align: justify;
  a {
    vertical-align: middle;
    display: inline-block;
    zoom: 1;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

//SALE BUTTON
.sale-button {
  padding: 0.5rem 1.5rem;
  margin: 0.5em 0;
  display: inline-block;
  border: 2px solid $js-body-font-color;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: white;
    background: $js-body-font-color;
  }
  @media #{$small-only} {
    padding: 5px 10px;
  }
}

.light .sale-button {
  color: white;
  border-color: white;
  &:hover {
    background: white;
    color: $js-body-font-color;
  }
}
