@import "setup";

$font-icon: "Font Awesome 5 Pro";
$font-icon-brands: "Font Awesome 5 Brands";

$font-icon-size: 14px;

//SOLIDS
$font-icon-menu: "\f0c9";
$font-icon-delete: "\f00d";
$font-icon-delete2: "\f057";
$font-icon-down: "\f078";
$font-icon-down2: "\f0d7";
$font-icon-up: "\f077";
$font-icon-up2: "\f0d8";
$font-icon-right: "\f054";
$font-icon-right2: "\f0da";
$font-icon-double-right: "\f101";
$font-icon-left: "\f053";
$font-icon-left2: "\f0d9";
$font-icon-double-left: "\f100";
$font-icon-check: "\f00c";
$font-icon-check2: "\f058";
$font-icon-info: "\f129";
$font-icon-eye: "\f06e";
$font-icon-plus: "\f067";
$font-icon-plus2: "\f055";
$font-icon-minus: "\f068";
$font-icon-minus2: "\f056";
$font-icon-dot: "\f111";
$font-icon-magnify: "\f111";
$font-icon-list: "\f03a";
$font-icon-filter: "\f0b0";
$font-icon-filter2: $font-icon-list;
$font-icon-sorting: "\f0dc";
$font-icon-sorting2: "\f160";
$font-icon-transport: "\f0d1";
$font-icon-home: "\f015";
$font-icon-facebook: "\f09a";
$font-icon-instagram: "\f16d";
$font-icon-youtube: "\f167";
$font-icon-linkedin: "\f0e1";
$font-icon-search: "\f002";
$font-icon-cart: "\f07a";
$font-icon-cart2: "\f291";
$font-icon-cart3: "\f290";
$font-icon-user: "\f007";
$font-icon-phone: "\f095";
$font-icon-position: "\f0ac";
$font-icon-heart: "\f004";
$font-icon-box: "\f466";
$font-icon-cube: "\f1b2";
$font-icon-certificate: "\f0a3";
$font-icon-redo: "\f01e";
$font-icon-lock: "\f023";
$font-icon-quote: "\f10e";
$font-icon-play: "\f04b";
$font-icon-signin: "\f2f6";
$font-icon-scale: "\f24e";
$font-icon-square: "\f45c";

//BRANDS
$font-icon-facebook: "\f09a";
$font-icon-instagram: "\f16d";
$font-icon-youtube: "\f167";
$font-icon-linkedin: "\f0e1";
$font-icon-twitter: "\f081";
$font-icon-pinterest: "\f0d3";
