/* COLUMNS
----------------------------------------------------------------------------- */
#left-area,
#main-area {
  display: block;
  float: left;
  margin: 0 0 40px 0;
}

#right-area {
  display: block;
  float: left;
  margin: 0 0 40px 0;
}

#left-area {
  width: $js-left-area-width;
  padding-right: $js-left-area-padding-right;
  display: none;
}

#left-area .content {
  width: 100%;
  padding: 0 0 0 $js-page-padding-left;
}

#main-area {
  width: $js-main-area-width-3-col;
  padding-left: $js-main-area-padding-left;
  padding-right: $js-main-area-padding-right;
}

#main-area .content {
  width: 100%;
}

#right-area {
  width: $js-right-area-width;
}

#right-area .content {
  width: 100%;
  padding: 0 $js-page-padding-right 0 $js-page-padding-left;
}

.hide-left-area #left-area {
  display: none;
}

.page-checkout #left-area,
.page-orderdetailsconfirmed #left-area {
  display: none;
}

//
//  One column
//
.one-column #main-area {
  width: 100%;
  //margin: 0 10px;
}

//
//  Two columns
//  This means left + main unless body.hide-left-area
//
.two-columns #main-area {
  width: $js-main-area-width-2-col;
}

.two-columns.hide-left-area #main-area {
  width: 75%;
}

.two-columns.hide-left-area #right-area {
  width: 25%;
}

.two-columns.hide-left-area #left-area {
  display: none;
}

@media #{$medium-down} {
  #right-area {
    display: none;
  }

  .two-columns #left-area {
    display: none;
  }

  .two-columns #main-area {
    width: 100%;
  }

  .two-columns.hide-left-area #main-area {
    width: 100%;
  }

  .two-columns.hide-left-area #right-area {
    width: 100%;
    float: none;
    clear: both;
  }
}

//
//  Three columns
//
.three-columns #main-area {
  width: $js-main-area-width-3-col;
}

@media #{$medium-down} {
  .three-columns #left-area {
    display: none;
  }
  .three-columns #main-area {
    width: 100%;
  }
  .three-columns #right-area {
    width: 100%;
    float: none;
    clear: both;
  }
  .three-columns #left-area {
    //display: none;
  }
}

@media #{$large-up} {
  .three-columns #main-area {
    width: 75%;
  }

  .three-columns #right-area {
    width: 25%;
  }
}

#border-top {
  clear: both;
  width: 100%;
  height: 40px;
  line-height: 40px;
}

#border-top .inner {
  width: 100%;
  max-width: $js-max-page-width;
  font-size: 0.85em;
  margin: 0 auto;
  overflow: hidden;
}
