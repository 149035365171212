//
//  There are more mixins available in scss/foundation_global.scss
//

//
//  Custom defined mixins
//

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow(
  $horizontal: 0,
  $vertical: 2px,
  $blur: 5px,
  $spread: 2px,
  $color: rgba(0, 0, 0, 0.24)
) {
  // Ignoring inset for now
  -webkit-box-shadow: $horizontal $vertical $blur $spread $color;
  -moz-box-shadow: $horizontal $vertical $blur $spread $color;
  box-shadow: $horizontal $vertical $blur $spread $color;
}

@mixin js-description-text($font-size) {
  font-family: $js-description-font-family;
  line-height: $font-size * 1.4;
  font-size: $font-size;
}

@mixin product-name-h3 {
  margin-bottom: 2px;
  font-size: $js-product-list-h3-font-size;
  font-weight: $js-product-list-h3-font-weight;
  a {
    color: $js-product-list-h3-color;
    margin: 0;
    height: auto; //TODO is this needed?
    overflow: hidden;
  }
}

@mixin calculate-item-width(
  $total-items,
  $margin-right,
  $selector,
  $margin-bottom: null,
  $important: null
) {
  $extra-width: ($margin-right / $total-items);
  $value: decimal-round(
    (100 / $total-items) - $margin-right + $extra-width,
    2,
    "floor"
  );
  $nth: ($total-items) + "n+0";
  // Output
  #{$selector} {
    width: add-unit($value, "%") $important;
    margin-right: add-unit($margin-right, "%") $important;
    @if $margin-bottom != null {
      margin-bottom: add-unit($margin-bottom, "%") $important;
    }
  }
  #{$selector}:nth-of-type(#{$nth}) {
    margin-right: 0 $important;
  }
}

@mixin js-button-global-style {
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  display: block;
  text-align: center;
  line-height: normal;
}

// Pagination

@mixin paging-link-style() {
  display: inline-block;
  font-size: $js-paging-control-link-font-size;
  font-family: $js-paging-control-link-font-family;
  text-decoration: none;
  font-weight: bold;
  color: $js-paging-control-link-color;
  background: $js-paging-control-link-background-color;
  padding: 5px 10px;
  border: $js-paging-control-link-border;
  @include border-radius($js-paging-control-link-border-radius);
  &:hover {
    color: $js-paging-control-link-hover-color;
    background: $js-paging-control-link-hover-background-color;
    border: $js-paging-control-link-hover-border;
  }
}

// Dynamic image layout
@mixin autosize-image-container($vertical-ratio) {
  position: relative;
  padding-top: $vertical-ratio;
  display: block;

  img {
    position: absolute;
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin number-badge($color) {
  color: white;
  background: $color;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  position: absolute;
  top: 5px;
  right: -5px;
}

@mixin number-badge-text() {
  margin-left: 4px;
  &:before {
    content: "(";
    margin-right: 1px;
  }
  &:after {
    content: ")";
    margin-left: 1px;
  }
}

@mixin product-row-title() {
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

@mixin stock-dot($inStock: false) {
  content: " ";
  display: inline-block;
  width: 8px;
  height: 8px;
  @include border-radius(50%);
  background: $outofstock-color;
  @if ($inStock) {
    background: $instock-color;
  }
}

@mixin scroll-bar() {
  overflow: auto;
  padding-right: 5px;
  scrollbar-width: thin;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eee;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }
}

//
//  Custom defined functions
//

@function grey-scale($shade) {
  $grayColors: #ffffff, #fafafa, #f9f9f9, #f6f6f6, #f5f5f5, #efefef, #eeeeee,
    #dddddd, #cccccc, #aaaaaa, #999999, #888888, #777777, #666666, #555555,
    #444444, #333333, #222222, #000000;
  @if $shade > length($grayColors) {
    $tone: length($grayColors);
  }
  @return nth($grayColors, $shade);
}

@function add-unit($string, $unit) {
  $unit: unquote($unit);
  @return $string + $unit;
}

// Rounding function: https://gist.github.com/terkel/4373420
@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{$number} is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{$digits} is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{$digits} has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return round($number * $n) / $n;
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{$mode} is undefined keyword.';
    @return $number;
  }
}

@function decimal-ceil($number, $digits: 0) {
  @return decimal-round($number, $digits, ceil);
}

@function decimal-floor($number, $digits: 0) {
  @return decimal-round($number, $digits, floor);
}
