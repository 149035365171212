//WATERCIRCLES
$c2-font-family: "Lato", sans-serif;
$c2-primary-color: #00aabc;
$c2-secondary-color: #f6a901;
$c2-menu-background: $c2-primary-color;
$c2-desktop-nav-background: #fff;
$c2-desktop-nav-color: $js-body-font-color;
$c2-footer-background: #fff;
$c2-footer-color: $js-body-font-color;
$c2-jetshop-background: #fff;
$c2-body-background: #fff;
$c2-button-info-background: #111;
$c2-button-buy-background: #111;

//GJENSIDIGE
$c3-font-family: "gjensidige", sans-serif;
$c3-primary-color: #233f53;
$c3-secondary-color: #f3ad00;
$c3-secondary-dark-color: #eb9119;
$c3-menu-background: $c3-primary-color;
$c3-desktop-nav-background: #fff;
$c3-desktop-nav-color: $js-body-font-color;
$c3-footer-background: #fff;
$c3-footer-color: $js-body-font-color;
$c3-jetshop-background: #fff;
$c3-body-background: #fff;
$c3-button-info-background: #111;
$c3-button-buy-background: #111;
