@import "../../../scss/foundation/functions";
@import "../../../scss/responsive-base/mixins";
@import "../../../scss/base_settings";

//FOLKSAM & MAIN
html.channel1 {
}

//WATERCIRCLES
html.channel6 {
  body {
    background: #f8f8f8;
  }
  //GENERAL
  //FONT
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  ul,
  ol,
  .button,
  button,
  ul li,
  #cat-nav li.lv1 > a,
  #header .desktop-category-nav ul li a,
  #mobile-nav ul.category-navigation > li > a,
  .category-page-wrapper ul li .product-price .price-amount,
  .releware-recommendation-wrapper ul li .product-price .price-amount,
  #startpage_list ul li .product-price .price-amount,
  .list-category ul li .product-price .price-amount,
  #product-tab-system-desktop header > a,
  .cart-area-wrapper .small-cart-body .title,
  #cat-nav ul.lv2 li.lv2 > a {
    @if $c2-font-family {
      font-family: $c2-font-family;
      font-weight: 300;
    }
  }

  iframe {
    max-width: 100%;
  }

  #content-wrapper {
    padding-top: 10px;
  }

  h1 {
    font-size: 2.8rem;
    @media #{$small-only} {
      font-size: 1.8rem;
    }
  }

  #cat-nav .nav-bar,
  #cat-nav li.lv1 > a,
  #cat-nav li.lv1.active > a {
    @media #{$large-up} {
      background: $c2-menu-background;
      //font-weight: $font-weight-bold;
      font-size: 16px;
    }
  }
  #cat-nav li.lv1:hover > a,
  #cat-nav li.active.lv1:hover > a {
    background: darken($c2-menu-background, 5%);
  }

  #menu-content #menu-activator.icon-button.menu-icon {
    border-right: darken($c2-primary-color, 5%);
  }
  #menu-content .icon-button:hover,
  .cart-area-wrapper .small-cart-body:hover,
  &.search-open #search-box-wrapper {
    @media #{$medium-down} {
      background: darken($c2-primary-color, 5%);
    }
  }

  #header {
    #info-text-left-wrapper {
      float: left;
      margin: 42px 0px 0 30px;
      @media #{$medium-only} {
        margin-top: 35px;
      }
      @media #{$small-only} {
        display: none;
      }
    }
    .logo-wrapper .logo-body {
      max-width: 240px;
      line-height: 0;
    }
    .SmallCartButtons {
      @media #{$large-up} {
        display: block;
        margin-left: 1rem;
        .button {
          color: white;
        }
      }
    }
    .search-box-wrapper {
      @media #{$large-up} {
        margin: 30px 40px 0 0;
        float: right;
        .search-box-input input {
          background-color: #fff;
          border: 1px solid #eee;
          &:focus {
            background-color: #f5f5f5;
          }
        }
      }
    }
    .fa-home:before {
      font-family: $font-icon;
    }
    .fa-search:before {
      font-family: $font-icon;
      content: $font-icon-search;
      font-size: 14px;
      @media #{$medium-down} {
        font-size: 20px;
      }
    }
    .fa-bars:before {
      font-family: $font-icon;
      content: $font-icon-menu;
      font-size: 20px;
    }
    .small-cart-body::before {
      font-family: $font-icon;
      content: $font-icon-cart;
      font-size: 28px;
      @media #{$medium-down} {
        color: white;
        font-size: 20px;
      }
    }
    .small-cart-items-value {
      &:before {
        margin-right: 3px;
        content: "(";
      }
      &:after {
        margin-left: 3px;
        content: ")";
      }
    }
  }

  h2 {
    color: $js-body-font-color;
  }

  //BACKGORUNDS PRIMARY
  #menu-content,
  .search-box-button a,
  .product-page-back-link a,
  &.page-responsive-checkout h2.component-header span.component-header-number,
  &.page-responsive-orderconfirmed
    h2.component-header
    span.component-header-number,
  .empty-cart-wrapper .continue-shopping-button.form-button {
    background: $c2-primary-color;
  }

  //COLORS PRIMARY
  .small-cart-body,
  h1,
  a,
  #path-nav .breadcrumb-link,
  &.page-responsive-checkout h2.component-header,
  &.page-responsive-orderconfirmed h2.component-header,
  &.page-responsive-checkout .discount-wrapper .discount-header,
  &.page-responsive-orderconfirmed .discount-wrapper .discount-header,
  #subcategories-container.subcategories-has-no-images .subcategories-name,
  #cat-nav ul.lv1 li.lv1.has-subcategories:before,
  #path-nav .breadcrumb-spacer,
  .box-header span {
    color: $c2-primary-color;
  }

  //BACKGORUNDS SECONDARY
  #dc-wrapper #dc-btns .button#dc-checkout-btn {
    background: $c2-secondary-color;
  }
  #dc-wrapper.reveal-modal #dc-btns .button#dc-checkout-btn:hover {
    background: darken($c2-secondary-color, 5%);
  }

  //COLORS SECONDARY
  .product-wrapper .price-promotion,
  &.page-listproductswithimageadvanced .price-promotion,
  .FrontRestProducts .price-promotion,
  &.page-showproduct .price-promotion,
  .offers-list-wrapper .price-promotion,
  .SearchPricePromotion,
  .simple-product-list-item-price .price-promotion span {
    color: $c2-secondary-color;
  }

  .product-page-back-link a,
  .button.Checkout {
    color: white;
  }

  #jetshop-branding {
    background: $c2-jetshop-background;
  }
  #wrapper-page-content {
    background: $c2-body-background;
    background-size: cover;
  }

  //BUTTON INFO
  .startpage-object a.button-info,
  a.ButtonInfo,
  .offers-list-item-more-info a,
  .offers-list-item-more-info img,
  a.advanced-info-button-text,
  a.RelatedProductMoreInfoButton,
  a.RelatedProductMoreInfoButton img,
  .FrontRestProducts a.button-info,
  a.advanced-info-button-text,
  .product-wrapper a.button-info,
  .releware-item-wrapper a.button-info {
    background: $c2-primary-color;
  }

  .button,
  a.view-button-related-products,
  .get-customer-info-btn,
  a.advanced-buy-button-text,
  a.view-button,
  a.view-button-related-products,
  a.button-disabled,
  a.ButtonInfo img,
  a.RelatedProductBuyButton img,
  table.ProductListHorizontalAdvanced table.ButtonBuyContainer td *,
  table.ProductListHorizontalAdvanced .ButtonInfo *,
  .newsletter-box-button > .button,
  .NewsArchiveButton a,
  .NewsArchiveButton img,
  div.filter-wrapper div.SumoSelect div.optWrapper div.MultiControls p,
  div.filter-wrapper div.SumoSelect div.optWrapper.multiple div.MultiControls p,
  a.view-button-related-products,
  .get-customer-info-btn,
  a.advanced-buy-button-text,
  a.view-button,
  a.view-button-related-products,
  a.button-disabled,
  a.ButtonInfo img,
  a.RelatedProductBuyButton img,
  table.ProductListHorizontalAdvanced table.ButtonBuyContainer td *,
  table.ProductListHorizontalAdvanced .ButtonInfo *,
  .newsletter-box-button > .button,
  div.filter-wrapper
    div.SumoSelect
    div.optWrapper
    div.MultiControls
    .newsletter-box-button
    > p,
  .NewsArchiveButton a,
  .NewsArchiveButton img,
  a.customer-club-optin,
  a.customer-club-optout {
    background: $c2-primary-color;
  }

  //BUTTON BUY
  a.RelatedProductBuyButton,
  a.buy-button,
  a.buy-button-first-text,
  a.ButtonBuy,
  .product-wrapper a.buy-button,
  .purchase-block-buy a.buy-button,
  a.buy-button-search,
  a.advanced-buy-button-text,
  a.buy {
    background: $c2-secondary-color;
  }

  .category-page-wrapper ul li .product-buttons .button-info,
  .releware-recommendation-wrapper ul li .product-buttons .button-info,
  #startpage_list ul li .product-buttons .button-info,
  .list-category ul li .product-buttons .button-info,
  #attribute-select[data-type="radio2"]
    > div
    .item-wrapper
    .item.selected
    .check-box:before {
    background: $c2-secondary-color;
  }

  //CART CEHCKOUT
  #dc-wrapper.dropdown #dc-btns .button#dc-checkout-btn,
  #dc-wrapper.dropdown
    #dc-btns
    div.filter-wrapper
    div.SumoSelect
    div.optWrapper
    div.MultiControls
    p#dc-checkout-btn,
  div.filter-wrapper
    div.SumoSelect
    div.optWrapper
    div.MultiControls
    #dc-wrapper.dropdown
    #dc-btns
    p#dc-checkout-btn,
  .wishlist-container .button.enter-add-to-cart-mode,
  .wishlist-container .button.wishlist-add-to-cart {
    background: $c2-secondary-color !important;
  }
}

.footer-wrapper {
  clear: both;
}

//GJENSIDIGE
html.channel7 {
  body {
    background: #f8f8f8;
  }
  //GENERAL
  //FONT
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  ul,
  ol,
  .button,
  button,
  ul li,
  #cat-nav li.lv1 > a,
  #header .desktop-category-nav ul li a,
  #mobile-nav ul.category-navigation > li > a,
  .category-page-wrapper ul li .product-price .price-amount,
  .releware-recommendation-wrapper ul li .product-price .price-amount,
  #startpage_list ul li .product-price .price-amount,
  .list-category ul li .product-price .price-amount,
  #product-tab-system-desktop header > a,
  .cart-area-wrapper .small-cart-body .title,
  #cat-nav ul.lv2 li.lv2 > a {
    @if $c3-font-family {
      font-family: $c3-font-family;
      font-weight: 300;
    }
  }

  iframe {
    max-width: 100%;
  }

  #content-wrapper {
    padding-top: 10px;
  }

  h1 {
    font-size: 2.8rem;
    @media #{$small-only} {
      font-size: 1.8rem;
    }
  }

  #cat-nav .nav-bar,
  #cat-nav li.lv1 > a,
  #cat-nav li.lv1.active > a {
    @media #{$large-up} {
      background: $c3-menu-background;
      //font-weight: $font-weight-bold;
      font-size: 16px;
    }
  }
  #cat-nav li.lv1:hover > a,
  #cat-nav li.active.lv1:hover > a {
    background: darken($c3-menu-background, 5%);
  }

  #menu-content #menu-activator.icon-button.menu-icon {
    border-right: darken($c3-primary-color, 5%);
  }
  #menu-content .icon-button:hover,
  .cart-area-wrapper .small-cart-body:hover,
  &.search-open #search-box-wrapper {
    @media #{$medium-down} {
      background: darken($c3-primary-color, 5%);
    }
  }

  #header {
    #info-text-left-wrapper {
      float: left;
      margin: 42px 0px 0 30px;
      @media #{$medium-only} {
        margin-top: 35px;
      }
      @media #{$small-only} {
        display: none;
      }
    }
    .logo-wrapper .logo-body {
      max-width: 240px;
      line-height: 0;
    }
    .SmallCartButtons {
      @media #{$large-up} {
        display: block;
        margin-left: 1rem;
        .button {
          color: white;
        }
      }
    }
    .search-box-wrapper {
      @media #{$large-up} {
        margin: 30px 40px 0 0;
        float: right;
        .search-box-input input {
          background-color: #fff;
          border: 1px solid #eee;
          &:focus {
            background-color: #f5f5f5;
          }
        }
      }
    }
    .fa-home:before {
      font-family: $font-icon;
    }
    .fa-search:before {
      font-family: $font-icon;
      content: $font-icon-search;
      font-size: 14px;
      @media #{$medium-down} {
        font-size: 20px;
      }
    }
    .fa-bars:before {
      font-family: $font-icon;
      content: $font-icon-menu;
      font-size: 20px;
    }
    .small-cart-body::before {
      font-family: $font-icon;
      content: $font-icon-cart;
      font-size: 28px;
      @media #{$medium-down} {
        color: white;
        font-size: 20px;
      }
    }
    .small-cart-items-value {
      &:before {
        margin-right: 3px;
        content: "(";
      }
      &:after {
        margin-left: 3px;
        content: ")";
      }
    }
  }

  h2 {
    color: $js-body-font-color;
  }

  //BACKGORUNDS PRIMARY
  #menu-content,
  .search-box-button a,
  .product-page-back-link a,
  &.page-responsive-checkout h2.component-header span.component-header-number,
  &.page-responsive-orderconfirmed
    h2.component-header
    span.component-header-number,
  .empty-cart-wrapper .continue-shopping-button.form-button {
    background: $c3-primary-color;
  }

  //COLORS PRIMARY
  .small-cart-body,
  h1,
  a,
  #path-nav .breadcrumb-link,
  &.page-responsive-checkout h2.component-header,
  &.page-responsive-orderconfirmed h2.component-header,
  &.page-responsive-checkout .discount-wrapper .discount-header,
  &.page-responsive-orderconfirmed .discount-wrapper .discount-header,
  #subcategories-container.subcategories-has-no-images .subcategories-name,
  #cat-nav ul.lv1 li.lv1.has-subcategories:before,
  #path-nav .breadcrumb-spacer,
  .box-header span {
    color: $c3-primary-color;
  }

  //BACKGORUNDS SECONDARY
  #dc-wrapper #dc-btns .button#dc-checkout-btn {
    background: $c3-secondary-color;
  }
  #dc-wrapper.reveal-modal #dc-btns .button#dc-checkout-btn:hover {
    background: darken($c3-secondary-color, 5%);
  }

  //COLORS SECONDARY
  .product-wrapper .price-promotion,
  &.page-listproductswithimageadvanced .price-promotion,
  .FrontRestProducts .price-promotion,
  &.page-showproduct .price-promotion,
  .offers-list-wrapper .price-promotion,
  .SearchPricePromotion,
  .simple-product-list-item-price .price-promotion span {
    color: $c3-secondary-color;
  }

  .product-page-back-link a,
  .button.Checkout {
    color: white;
  }

  #jetshop-branding {
    background: $c3-jetshop-background;
  }
  #wrapper-page-content {
    background: $c3-body-background;
    background-size: cover;
  }

  //BUTTON INFO
  .startpage-object a.button-info,
  a.ButtonInfo,
  .offers-list-item-more-info a,
  .offers-list-item-more-info img,
  a.advanced-info-button-text,
  a.RelatedProductMoreInfoButton,
  a.RelatedProductMoreInfoButton img,
  .FrontRestProducts a.button-info,
  a.advanced-info-button-text,
  .product-wrapper a.button-info,
  .releware-item-wrapper a.button-info {
    background: $c3-primary-color;
  }

  .button,
  a.view-button-related-products,
  .get-customer-info-btn,
  a.advanced-buy-button-text,
  a.view-button,
  a.view-button-related-products,
  a.button-disabled,
  a.ButtonInfo img,
  a.RelatedProductBuyButton img,
  table.ProductListHorizontalAdvanced table.ButtonBuyContainer td *,
  table.ProductListHorizontalAdvanced .ButtonInfo *,
  .newsletter-box-button > .button,
  .NewsArchiveButton a,
  .NewsArchiveButton img,
  div.filter-wrapper div.SumoSelect div.optWrapper div.MultiControls p,
  div.filter-wrapper div.SumoSelect div.optWrapper.multiple div.MultiControls p,
  a.view-button-related-products,
  .get-customer-info-btn,
  a.advanced-buy-button-text,
  a.view-button,
  a.view-button-related-products,
  a.button-disabled,
  a.ButtonInfo img,
  a.RelatedProductBuyButton img,
  table.ProductListHorizontalAdvanced table.ButtonBuyContainer td *,
  table.ProductListHorizontalAdvanced .ButtonInfo *,
  .newsletter-box-button > .button,
  div.filter-wrapper
    div.SumoSelect
    div.optWrapper
    div.MultiControls
    .newsletter-box-button
    > p,
  .NewsArchiveButton a,
  .NewsArchiveButton img,
  a.customer-club-optin,
  a.customer-club-optout {
    background: $c3-primary-color;
  }

  //BUTTON BUY
  a.RelatedProductBuyButton,
  a.buy-button,
  a.buy-button-first-text,
  a.ButtonBuy,
  .product-wrapper a.buy-button,
  .purchase-block-buy a.buy-button,
  a.buy-button-search,
  a.advanced-buy-button-text,
  a.buy {
    background: $c3-secondary-color;
  }

  .category-page-wrapper ul li .product-buttons .button-info,
  .releware-recommendation-wrapper ul li .product-buttons .button-info,
  #startpage_list ul li .product-buttons .button-info,
  .list-category ul li .product-buttons .button-info,
  #attribute-select[data-type="radio2"]
    > div
    .item-wrapper
    .item.selected
    .check-box:before {
    background: $c3-secondary-color;
  }

  //CART CEHCKOUT
  #dc-wrapper.dropdown #dc-btns .button#dc-checkout-btn,
  #dc-wrapper.dropdown
    #dc-btns
    div.filter-wrapper
    div.SumoSelect
    div.optWrapper
    div.MultiControls
    p#dc-checkout-btn,
  div.filter-wrapper
    div.SumoSelect
    div.optWrapper
    div.MultiControls
    #dc-wrapper.dropdown
    #dc-btns
    p#dc-checkout-btn,
  .wishlist-container .button.enter-add-to-cart-mode,
  .wishlist-container .button.wishlist-add-to-cart {
    background: $c3-secondary-color !important;
  }
}

.footer-wrapper {
  clear: both;
}
