html.channel6,
html.channel7 {
  &.page-responsive-checkout {
    .discount-wrapper .discount-button {
      background-color: $c2-primary-color !important;
      position: relative;
      top: -1px;
    }
  }
}

html.channel7 {
  &.page-responsive-checkout {
    .discount-wrapper .discount-button {
      background-color: $c3-primary-color !important;
    }
  }
}
