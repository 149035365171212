//GENERAL
html.page-default {
  #sub-nav-wrapper,
  #path-nav,
  .startpage-header,
  .startpage-image {
    display: none !important;
  }

  //ALLOWING FOR FULL SCREEN
  #content-wrapper {
    //max-width: none;
  }
  #main-area {
    //@media only screen and (min-width: $js-max-page-width){
    //padding: 0 !important;
    //}
  }
  .startpage-text {
    margin: 0;
    display: none;
  }
}

//ITEMS
#startpage_list {
  > * {
    /*max-width: $js-max-page-width;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: $js-max-page-width) {
      padding: 0 $page-padding;
    }
    &.columns-2,
    &.columns-3,
    &.columns-4 {
      margin-bottom: #{$start-page-margin-bottom / 2 + "%"} !important;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }*/
  }
  .header {
    padding: 0 0 20px 0;
    text-align: center;
    > *:last-child {
      margin-bottom: 0;
    }
  }
  img {
  }
  > ul {
    > li {
      padding: 0 !important;
      border: none !important;
      float: left;
      margin-bottom: #{$start-page-margin-bottom + "%"} !important;
    }
  }

  //ITEMS
  .startpage-custom-item {
    position: relative;
    .custom-item-image {
      width: 100%;
    }
    .custom-item-text {
      text-align: center;

      h1 {
        font-size: 3rem;
        @media #{$medium-down} {
          font-size: 2rem;
        }
      }
      h2 {
        font-size: 2rem;
        @media #{$medium-down} {
          font-size: 1.4rem;
        }
      }
      h3 {
        font-size: 1.1rem;
        @media #{$medium-down} {
          font-size: 1rem;
        }
      }
      h4 {
        font-size: 0.9rem;
        @media #{$medium-down} {
          font-size: 0.9rem;
        }
      }
    }
    hr {
      margin: 7px 0;
    }
  }
  .list-category {
    padding: 0 0;
  }
  b p {
    font-weight: bold;
  }
  .startpage-product-item {
    padding: $js-product-wrapper-padding;
    border: 1px solid $border-color;
    .product-buttons {
      a {
        width: 100% !important;
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-1> li"
  );
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-2> li"
  );
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-3> li"
  );
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-4> li"
  );
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-1> li"
  );
  @include calculate-item-width(
    2,
    $start-page-margin-right,
    ".startpage-items ul.items-2> li"
  );
  @include calculate-item-width(
    2,
    $start-page-margin-right,
    ".startpage-items ul.items-3> li"
  );
  @include calculate-item-width(
    2,
    $start-page-margin-right,
    ".startpage-items ul.items-4> li"
  );
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-1> li"
  );
  @include calculate-item-width(
    2,
    $start-page-margin-right,
    ".startpage-items ul.items-2> li"
  );
  @include calculate-item-width(
    3,
    $start-page-margin-right,
    ".startpage-items ul.items-3> li"
  );
  @include calculate-item-width(
    4,
    $start-page-margin-right,
    ".startpage-items ul.items-4> li"
  );
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-1> li"
  );
  @include calculate-item-width(
    2,
    $start-page-margin-right,
    ".startpage-items ul.items-2> li"
  );
  @include calculate-item-width(
    3,
    $start-page-margin-right,
    ".startpage-items ul.items-3> li"
  );
  @include calculate-item-width(
    4,
    $start-page-margin-right,
    ".startpage-items ul.items-4> li"
  );
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(
    1,
    $start-page-margin-right,
    ".startpage-items ul.items-1> li"
  );
  @include calculate-item-width(
    2,
    $start-page-margin-right,
    ".startpage-items ul.items-2> li"
  );
  @include calculate-item-width(
    3,
    $start-page-margin-right,
    ".startpage-items ul.items-3> li"
  );
  @include calculate-item-width(
    4,
    $start-page-margin-right,
    ".startpage-items ul.items-4> li"
  );
}
