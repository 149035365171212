//CHANNEL2
html.channel6,
html.channel7 {
  .purchase-block-quantity {
    width: 19%;
    display: inline-block;
    margin: 0 1% 0 0;
    input {
      height: 46px;
      width: 100%;
      border: 1px solid #ccc;
    }
  }
  .purchase-block-buy {
    width: 80%;
    display: inline-block;
    a.buy-button {
      height: 46px;
      line-height: 46px;
      padding: 0 5px;
      border-radius: 0;
      width: 100%;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      &:hover {
        background: darken($c2-secondary-color, 5%);
      }
    }
  }
}

//CHANNEL3
html.channel7 {
  .purchase-block-quantity {
    input {
      border-radius: 5px;
    }
  }
  .purchase-block-buy {
    a.buy-button {
      color: $c3-primary-color;
      border-radius: 5px;
      text-transform: none;
      span {
        font-weight: $font-weight-bold !important;
      }
    }
  }
}
