//OVERLAY CLASSES
.overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.overlay.center {
  width: 100%;
  text-transform: uppercase;
  &.top,
  &.bottom {
    -webkit-transform: translateX(-50%) translateY(0%);
    -ms-transform: translateX(-50%) translateY(0%);
    -o-transform: translateX(-50%) translateY(0%);
    transform: translateX(-50%) translateY(0%);
  }
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
    top: initial;
  }
}
.overlay.light {
  > * {
    color: white;
  }
}
.overlay.left,
.overlay.right {
  position: absolute;
  padding: 20px;
  text-align: left;
  -webkit-transform: translateX(0%) translateY(-50%);
  -ms-transform: translateX(0%) translateY(-50%);
  -o-transform: translateX(0%) translateY(-50%);
  transform: translateX(0%) translateY(-50%);
  &.top,
  &.bottom {
    -webkit-transform: translateX(0%) translateY(0%);
    -ms-transform: translateX(0%) translateY(0%);
    -o-transform: translateX(0%) translateY(0%);
    transform: translateX(0%) translateY(0%);
  }
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
    top: initial;
  }
}
.overlay.right {
  right: 0;
  left: 50%;
  text-align: right;
}
.overlay.left {
  left: 0;
}
.overlay.center {
  text-align: center;
}

.under {
  padding: 2rem 0;
}
