//
//  General style for buttons
//

//TODO gradient mixin, test to replace $js-button-background-color with a mixin for gradient


// Placeholders for grouped selectors
$buttons-normal-style: ();
$buttons-buy-style: ();
$buttons-info-style: ();
$buttons-to-checkout-style: ();
$buttons-rightarea-style: ();

// Lists of selectors for groups
$buttons-normal: (
        ".button",
        "a.view-button-related-products",
        ".get-customer-info-btn",
        "a.advanced-buy-button-text",
        "a.view-button",
        "a.view-button-related-products",
        "a.button-disabled",
        "a.ButtonInfo img",
        "a.RelatedProductBuyButton img",
        "table.ProductListHorizontalAdvanced table.ButtonBuyContainer td *",
        "table.ProductListHorizontalAdvanced .ButtonInfo *",
        ".newsletter-box-button > .button",
        ".NewsArchiveButton a",
        ".NewsArchiveButton img",
);

$buttons-buy: (
        "a.RelatedProductBuyButton",
        "a.buy-button",
        "a.buy-button-first-text",
        "a.ButtonBuy",
        ".product-wrapper a.buy-button",
        ".purchase-block-buy a.buy-button",
        "a.buy-button-search",
        "a.advanced-buy-button-text",
        "a.buy",
);

$buttons-info: (
        ".startpage-object a.button-info",
        "a.ButtonInfo",
        ".offers-list-item-more-info a",
        ".offers-list-item-more-info img",
        "a.advanced-info-button-text",
        "a.RelatedProductMoreInfoButton",
        "a.RelatedProductMoreInfoButton img",
        ".FrontRestProducts a.button-info",
        "a.advanced-info-button-text",
        ".product-wrapper a.button-info",
        ".releware-item-wrapper a.button-info",
);

$buttons-to-checkout: (
        "a.checkout-text",
);

$buttons-rightarea: (
        ".news-list-item-link .button",
        ".NewsListArchiveItemLink .button",
        ".view-button-offers-text",
);

//
// This is the style for all items in list $buttons-normal
//
@each $item in $buttons-normal {
  $buttons-normal-style: append($buttons-normal-style, unquote('#{$item}'), 'comma');
}

#{$buttons-normal-style} {
  @if length($buttons-normal) > 0 {
    @include js-button-global-style();
    font-size: $js-button-font-size;
    font-weight: $js-button-font-weight;
    padding: $js-button-padding;
    color: $js-button-color;
    background: $js-button-background-color;
    letter-spacing: $js-button-letter-spacing;
    text-transform: $js-button-text-transform;
    border-radius: $js-button-border-radius;
    border-width: $js-button-border-width;
    border-color: $js-button-border-color;
    &:hover {
      text-decoration: none;
      color: $js-button-color;
      background-color: $primary-hover;
      border-width: $js-button-border-width;
      border-color: darken($js-button-border-color, $js-button-hover-darken-factor);
    }
    &.button-disabled {
      background-color: $js-button-disabled-background-color;
      border-color: $js-button-disabled-border-color;
    }
    &:active {
      text-decoration: none;
      color: $js-button-color;
    }
    &:focus {
      text-decoration: none;
      color: $js-button-color;
    }
  }
}

//
// This is the style for all items in list $buttons-buy
//
@each $item in $buttons-buy {
  $buttons-buy-style: append($buttons-buy-style, unquote('#{$item}'), 'comma');
}

#{$buttons-buy-style} {
  @if length($buttons-buy) > 0 {
    @include js-button-global-style();
    font-size: $js-button-font-size;
    font-weight: $js-button-font-weight;
    padding: $js-button-padding;
    color: $js-button-color;
    background: $js-button-buy-background-color;
    letter-spacing: $js-button-letter-spacing;
    text-transform: $js-button-text-transform;
    border-radius: $js-button-border-radius;
    border-width: $js-button-border-width;
    border-color: $js-button-border-color;
    &:hover {
      color: $js-button-color;
      background-color: $secondary-hover;
      border-width: $js-button-border-width;
      border-color: darken($js-button-border-color, $js-button-hover-darken-factor);
    }
    &.button-disabled {
      background-color: $js-button-disabled-background-color;
      border-color: $js-button-disabled-border-color;
    }
    &:active {
      text-decoration: none;
      color: $js-button-color;
    }
    &:focus {
      text-decoration: none;
      color: $js-button-color;
    }
  }
}

//
// This is the style for all items in list $buttons-info
//
@each $item in $buttons-info {
  $buttons-info-style: append($buttons-info-style, unquote('#{$item}'), 'comma');
}

#{$buttons-info-style} {
  @if length($buttons-info) > 0 {
    @include js-button-global-style();
    font-size: $js-button-font-size;
    font-weight: $js-button-font-weight;
    padding: $js-button-padding;
    color: $js-button-color;
    background: $js-button-info-background-color;
    letter-spacing: $js-button-letter-spacing;
    text-transform: $js-button-text-transform;
    border-radius: $js-button-border-radius;
    border-width: $js-button-border-width;
    border-color: $js-button-border-color;
    &:hover {
      text-decoration: none;
      color: $js-button-color;
      background-color: $primary-hover;
      border-width: $js-button-border-width;
      border-color: darken($js-button-border-color, $js-button-hover-darken-factor);
    }
    &.button-disabled {
      background-color: $js-button-disabled-background-color;
      border-color: $js-button-disabled-border-color;
    }
    &:active {
      text-decoration: none;
      color: $js-button-color;
    }
    &:focus {
      text-decoration: none;
      color: $js-button-color;
    }
  }
}

//
// This is the style for all items in list $buttons-to-checkout
//
@each $item in $buttons-to-checkout {
  $buttons-to-checkout-style: append($buttons-to-checkout-style, unquote('#{$item}'), 'comma');
}

#{$buttons-to-checkout-style} {
  @if length($buttons-to-checkout) > 0 {
    @include js-button-global-style();
    font-size: $js-button-font-size;
    font-weight: 400;
    padding: 11px 30px 12px 18px;
    color: $js-body-font-color;
    background: $js-button-to-checkout-background-color;
    text-transform: none;
    border-radius: $js-button-border-radius;
    border-width: $js-button-border-width;
    border-color: $js-button-border-color;
    &:hover {
      text-decoration: none;
      color: $js-body-font-color;
      background-color: $secondary-hover;
      border-width: $js-button-border-width;
      border-color: darken($js-button-border-color, $js-button-hover-darken-factor);
    }
    &.button-disabled {
      background-color: $js-button-disabled-background-color;
      border-color: $js-button-disabled-border-color;
    }
    &:active {
      text-decoration: none;
      color: $js-body-font-color;
    }
    &:focus {
      text-decoration: none;
      color: $js-body-font-color;
    }
  }
}

//
// This is the style for all items in list $buttons-rightarea
//
@each $item in $buttons-rightarea {
  $buttons-rightarea-style: append($buttons-rightarea-style, unquote('#{$item}'), 'comma');
}

#{$buttons-rightarea-style} {
  @if length($buttons-rightarea) > 0 {
    @include js-button-global-style();
    display: inline-block;
    font-size: $js-button-rightarea-font-size;
    font-weight: $js-button-font-weight;
    padding: $js-button-rightarea-border-padding;
    color: $js-button-rightarea-color;
    background: $js-button-rightarea-background-color;
    letter-spacing: $js-button-letter-spacing;
    text-transform: $js-button-text-transform;
    border: $js-button-rightarea-border-width solid $js-button-rightarea-border-color;
    &:hover {
      text-decoration: none;
      color: $js-button-color;
      background-color: darken($js-button-rightarea-background-color-hover, $js-button-hover-darken-factor);
      border-width: $js-button-rightarea-border-width;
      border-color: darken($js-button-rightarea-border-color, $js-button-hover-darken-factor);
      &:focus {
        color: $js-button-color;
      }
    }
    &.button-disabled {
      background-color: $js-button-disabled-background-color;
      border-color: $js-button-disabled-border-color;
    }
    &:active {
      text-decoration: none;
      color: $js-button-rightarea-color;
    }
    &:focus {
      text-decoration: none;
      color: $js-button-rightarea-color;
    }
  }
}

//
//  Page-specific overrides
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////

//
// Page list products
//
.category-page-wrapper, .releware-recommendation-wrapper, #startpage_list, #list-category-placeholder {
  ul {
    li {
      .product-buttons,
      .product-buttons:hover {
        margin: 0 0 5px 0;
        overflow: hidden;
        a {
          float: left;
          display: inline-block;
          width: 48%;
          padding-left: 3px;
          padding-right: 3px;
          margin: 0 1%;
        }
      }
    }
  }
}

//
//  Link to checkout in header
//
a.checkout-text {
  position: relative;
  font-size: $js-button-checkout-icon-size;
  &::after {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    content: "\f105";
    font-size: $js-button-checkout-icon-size;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: ($js-button-checkout-icon-size / -2);
    right: 13px;
    line-height: 1;
  }
  &.button-disabled {
    background-color: $js-header-background-color;
    color: #555555;
    span,
    &::after {
      opacity: 0.5;
    }
    &:hover {
      cursor: default;
    }
  }
}

//
//  Startpage objects
//

#startpage_list {
  ul {
    li {
      .product-wrapper {
        .product-buttons,
        .product-buttons:hover {
          margin: 0 0 5px 0;
          overflow: hidden;
          a {
            width: 100%;
          }
        }
      }
    }
  }
}


//
//  Page show product
//
.purchase-block-buy a.buy-button {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  margin: 0;
  padding: 8px 22px;
}

//
//  Fancybox login
//
#bodyFastLoginBox {
  a.button {
    display: inline-block;
  }
}

//
//  News list
//
.news-list-wrapper {
  .news-list-item-link {
    text-align: right;
    .button {
      display: inline-block;
    }
  }
}

//
//  Offers list
//
.offers-list-item-wrapper.box-wrapper a.button.view-button-offers-text {
  float: right;
  margin: 5px;
}

