@import "ebeling-start-page-settings";

/*------------------------------------------------------
------------ ROW CLASSES (SET ON FIRST ITEM) -----------
------------------------------------------------------*/

//HEROAREA 1
.hero-area[data-style="1"] {
  margin-bottom: #{$start-page-margin-bottom + "%"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media #{$large-up} {
    flex-direction: row;
    justify-content: space-between;
  }
  .primary {
    @media #{$large-up} {
      width: 66.67%;
    }
  }
  .secondary {
    @media #{$medium-only} {
      display: flex;
    }
    @media #{$large-up} {
      width: 33.33%;
    }
  }
}

//HEROAREA 2
.hero-area[data-style="2"] {
  margin-bottom: #{$start-page-margin-bottom + "%"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media #{$medium-up} {
    flex-direction: row;
  }
  .primary {
    width: 100%;
    margin-bottom: 4%;
    @media #{$medium-up} {
      width: 49%;
      margin-bottom: 0;
    }
  }
  .secondary {
    width: 100%;
    @media #{$medium-up} {
      width: 49%;
    }
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div:nth-of-type(1),
    > div:nth-of-type(2) {
      width: 48%;
    }
    > div:nth-of-type(3) {
      width: 100%;
      margin-top: 3.9%;
    }
  }
}

//HEROAREA WITH SLIDER
.hero-area-slider {
  margin-bottom: #{$start-page-margin-bottom + "%"};
  overflow: hidden;
  .custom-item-link {
    display: block;
    position: relative;
  }
  .custom-item-text {
    display: block !important;
  }
  .slider-wrapper {
    width: 66.8%;
    float: right;
    margin: 0;
    @media #{$medium-down} {
      width: 100%;
      float: none;
      margin-bottom: 1%;
    }
    @media #{$small-only} {
      width: 100%;
      margin-bottom: 1.5%;
    }
    .slick-slide {
      margin: 0;
    }
    .slick-arrow {
      height: 40px;
      width: 40px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      &:before {
        color: white;
        font-size: 30px;
        margin: 0;
        line-height: 40px;
      }
    }
    .slick-next {
      right: 5px;
    }
    .slick-prev {
      left: 5px;
    }
  }
  .image-box-wrapper {
    width: 32.6%;
    float: left;
    @media #{$medium-down} {
      width: 100%;
      > div {
        width: 49.5%;
        float: left;
        &:last-child {
          float: right;
        }
      }
    }
    @media #{$small-only} {
      > div {
        width: 100%;
        float: none;
      }
    }
    > div:first-child {
      margin-bottom: 2.4%;
      @media #{$medium-down} {
        float: left;
        margin-bottom: 1%;
      }
      @media #{$small-only} {
        margin-bottom: 1.5%;
      }
    }
  }
}

//START SLIDER
.start-slider {
  margin-bottom: #{$start-page-margin-bottom + "%"};
  .custom-item-link {
    display: block;
    position: relative;
  }
  .slick-slide {
    margin: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
  }
  .custom-item-text {
  }
}

//PRODUCTAREA
.product-area {
  margin-bottom: #{$start-page-margin-bottom + "%"};
  overflow: hidden;
  > div {
    position: relative;
    margin-bottom: 0;
    &:nth-of-type(1) {
      width: 32.6%;
      float: left;
      @media #{$medium-down} {
        width: 100%;
        margin-bottom: 1.5%;
      }
    }
    &:nth-of-type(2) {
      width: 65.8%;
      float: right;
      @media #{$medium-down} {
        width: 100%;
      }
      .list-category {
        li {
          float: left;
          .product-name h3 {
            font-size: 12px !important;
          }
          .price-amount {
            font-size: 14px !important;
          }
          display: none !important;
          &:nth-of-type(-n + 8) {
            display: block !important;
          }
        }
      }
    }
  }
}

.product-area,
.start-slider,
.hero-area {
  &.fullscreen {
    max-width: none !important;
    padding: 0 !important;
  }
}
