.list-category-placeholder {
  padding: 10px 0 0 0;
  width: 100%;
  > .product-row-title {
    @include product-row-title;
  }
  header {
    > a {
      float: right;
      padding-top: 6px;
      color: $secondary-color;
    }
  }
  ul {
    margin: 0;
    li {
      .product-image {
        padding-top: 100% !important;
      }
    }
  }
  &.slider {
    ul {
      margin: 0 -10px;
      li {
        margin: 0 10px !important;
        clear: none !important;
      }
    }
    .slick-prev {
      left: 10px;
    }
    .slick-next {
      right: 10px;
    }
    .product-item {
      &:before {
        display: none !important;
      }
    }
  }
}

.start-grid {
  background: none !important;
}

.product-wrapper {
  .product-list-description {
    max-height: 95px;
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        rgba(255, 255, 255, 0) 80px,
        rgba(255, 255, 255, 1)
      );
    }
  }
}

$list-category-selector: ".list-category-placeholder:not(.slider) ul li";

//MEDIAQUERIES

//
//  Medium
//
@media #{$small-only} {
  @include calculate-item-width(
    $js-total-products-small,
    $js-product-wrapper-margin-right,
    $list-category-selector,
    $js-product-wrapper-margin-bottom
  );
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(
    $js-total-products-medium,
    $js-product-wrapper-margin-right,
    $list-category-selector,
    $js-product-wrapper-margin-bottom
  );
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(
    $js-total-products-large,
    $js-product-wrapper-margin-right-large-up,
    $list-category-selector,
    $js-product-wrapper-margin-bottom-large-up
  );
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(
    $js-total-products-xlarge,
    $js-product-wrapper-margin-right-large-up,
    $list-category-selector,
    $js-product-wrapper-margin-bottom-large-up
  );
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(
    $js-total-products-xxlarge,
    $js-product-wrapper-margin-right-large-up,
    $list-category-selector,
    $js-product-wrapper-margin-bottom-large-up
  );
}
