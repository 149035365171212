html.page-responsive-orderconfirmed {

  .order-details-confirmed-summary-wrapper {
    margin-bottom: 12px;
  }

  .order-details-confirmed-box {
    margin-bottom: 22px;
  }

}