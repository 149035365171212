/*

        LEFT CATEGORY NAVIGATION

*/
#left-cat-nav {
  margin-bottom: 20px;
  padding: 0;
}


.start-page #left-cat-nav {
  margin-top: 20px;
  display: none;
}

#left-cat-nav ul,
#left-cat-nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}

#left-cat-nav a {
  display: block;
  transition: none;
  color: $js-leftnav-link-color;
}

#left-cat-nav a:hover {
  text-decoration: underline;
  color: $js-leftnav-link-hover-color;

}

/* LV1 */
#left-cat-nav li.lv1 {
  display: none;
}

#left-cat-nav li.lv1.active {
  display: block;
  padding: 8px 0 7px;
}


#left-cat-nav li.lv1:hover > a:after {
}

#left-cat-nav li.lv1 > a {
  color: $js-leftnav-link-lv2-color;
  font-size: 20px;
  font-weight: bold;
  padding: 3px 10px 3px 15px;
  display: block;
}

#left-cat-nav li.lv1 > a:hover {
  color: $js-leftnav-link-lv2-hover-color;
}


/* LV2 */
#left-cat-nav li.selected > a {
  color: $js-leftnav-link-color;
  font-weight: bold;
  word-wrap: break-word;
}

#left-cat-nav li.selected > a:hover {
  color: $js-leftnav-link-hover-color;
}


#left-cat-nav ul.lv2 {
  display: none;
}


#left-cat-nav .active > ul.lv2 {
  display: block;
  padding: 0 0 6px 0px;
}

#left-cat-nav li.lv1:hover ul.lv2 {

}

#left-cat-nav li.lv2 {
  position: relative;
  margin: 0 0 3px;
}


#left-cat-nav li.lv2 > a {
  font-size: 14px;
  padding: 2px 10px 2px 15px;
  font-weight: 300;
  line-height: 1.1;
}

#left-cat-nav li.lv2.active > a {
  font-weight: bold;
}



#left-cat-nav li.lv2 > a:hover {
}

#left-cat-nav .lv2.has-subcategories > a:before,
#left-cat-nav .lv3.has-subcategories > a:before {
  content: "";
  display: block;
  position: absolute;
  left: 8px;
  top: 7px;
  width: 0px;
  height: 0px;
  border: 3px solid transparent;
  border-left-color: #333;
}

#left-cat-nav .lv3.has-subcategories > a:before {
  left: 28px;
}

#left-cat-nav .lv2.active.has-subcategories > a:before,
#left-cat-nav .lv3.active.has-subcategories > a:before {
  border: 3px solid transparent;
  border-top-color: #333;
  left: 7px;
}

#left-cat-nav .lv3.active.has-subcategories > a:before {
  left: 27px;
}




#left-cat-nav li.lv2 > a:hover {
}

/* LV3 */
#left-cat-nav ul.lv3 {
  margin: 1px 0 6px;
  font-size: 13px;
  display: none;
}

#left-cat-nav .active > ul.lv3 {
  display: block;
}

#left-cat-nav li.lv3 {
  position: relative;

}

#left-cat-nav li.lv3 > a {
  padding: 2px 10px 1px 35px;
}

#left-cat-nav li.lv3 > a:hover {
}

/* LV4 */
#left-cat-nav ul.lv4 {
  margin: 1px 0 6px;
  font-size: 13px;
  display: none;
}

#left-cat-nav .active > ul.lv4 {
  display: block;
}


#left-cat-nav li.lv4 {

}

#left-cat-nav li.lv4 > a {
  padding: 2px 10px 1px 50px;
}

#left-cat-nav li.lv4 > a:hover {
}
